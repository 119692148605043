<template>
  <svg class="icon-sort-desc" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_82_5074" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_82_5074)">
      <path d="M11.8497 20C12.023 19.9932 12.2234 19.9219 12.3497 19.803L16.4711 15.9241C16.7073 15.6614 16.8047 15.1573 16.5316 14.8635C16.2626 14.5742 15.741 14.5828 15.471 14.871L12.577 17.5982V4.72731C12.577 4.3257 12.2513 4 11.8497 4C11.4481 4 11.1224 4.32569 11.1224 4.72731V17.5982L8.22836 14.871C7.97978 14.6227 7.43966 14.5769 7.16776 14.8635C6.89567 15.15 6.98259 15.672 7.22846 15.9241L11.3498 19.803C11.4908 19.9357 11.6564 20.0003 11.8498 20H11.8497Z" fill="#0D092E"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconSortDesc"
}
</script>

<style scoped>

</style>